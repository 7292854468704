.sort-chevron-down{
  width:20px;
  height: 18px;
  background-image: url("~chevron.svg");
  transform: rotate(180deg);
  background-repeat: no-repeat;
}
.sort-chevron-up{
  width:20px;
  height: 18px;
  margin-left: 2px;
  background-image: url("~chevron.svg");
  background-repeat: no-repeat;
}

.rotate-chevron{
  transform: rotate(180deg);
}

.toggle-checkbox:checked~ .toggle-status::before{
  content: attr(data-toggle-on);
}

.toggle-checkbox~ .toggle-status::before{
  content: attr(data-toggle-off);
}

.notification-bell {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: none;
  outline: none;
  border-radius: 50%;
}

.notification-bell:hover {
  cursor: pointer;
}

.notification__badge {
  position: absolute;
  top: -4px;
  right: -4px;
  width: 12px;
  height: 12px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.ow-anywhere {
  overflow-wrap: anywhere;
}

.dropdown-shadow{
  box-shadow: 0px 3px 6px 1px #bdbdbc;
}

@-moz-document url-prefix() {
  .fire-fox-break-all { word-break: break-all; } 
}
.bg-yellow-img{
    height:420px;
    @media screen and (max-width:991px) {
      display: none;
    }
    @media screen and (min-width:992px) and (max-width:1199px)and (max-height:1365px){
      height:200px;
    }
    @media screen and (min-width:1200px) and (max-width:1349px) {
      height:250px;
    }
    @media screen and (min-width:1350px) and (max-width:1600px) {
      height:300px;
    }
}
  
  .bg-blue-img{
    height: 450px;
  }
  .bg-opaque-blue{
    background-color: #87CEFA;
  }
  .bg-pink{
    background-color: #ffc7c7;
  }
  
  .bg-yellow-img-txt{
    left: 70px;
    font-size: 48px;
    line-height: 54px;
    font-weight: 700;
    top: 75px;
    span{
      color: white;
    }
    @media screen and (max-width:991px) {
      display: none;
    }
    @media screen and (min-width:992px) and (max-width:1199px) and (max-height:1365px){
      left:10px;
      line-height: 24px;
      top:10px;
      font-size:18px;
    }
    @media screen and (min-width:1200px) and (max-width:1349px) {
      left:10px;
      line-height: 26px;
      top:20px;
      font-size:20px;
    }
    @media screen and (min-width:1350px) and (max-width:1600px){
      left: 20px;
      line-height: 32px;
      top: 30px;
      font-size: 24px;
    }
    @media screen and (min-width:1601px) and (max-width:1750px){
      left: 30px;
      line-height: 46px;
      top: 50px;
      font-size: 40px;
    }
    @media screen and (min-width:1751px) and (max-width:1900px){
      left: 30px;
      line-height: 52px;
      top: 50px;
      font-size: 46px;
    }
  }
  .phone-container{
    .iti{
      width: 100%;
    }
  }
  .main-section{
    background-image: url("~bk-pattern.svg");
    background-repeat: repeat;
    background-size: auto;
    .main{
      z-index:1;
      .main-container{
        width:800px;
        margin-top:50px;
        @media screen and (min-height:1366px){
          margin-top:270px;
        }
        @media screen and (min-width:992px) and (max-width:1199px) and (max-height:1365px){
          margin-top: 85px;
        }
        .img-txt{
            font-weight:700;
            font-size: 20px;
            right:25px;
            top:17px;
        }
        @media screen and (max-width:991px) {
          width: 100%;
          margin-top: 0px;
        }
        .body-container{
          background-color:  #F4F4F4;
          .logo{
            margin-bottom: 40px;
            @media screen and (max-width:991px) {
              width:250px;
            }
            @media screen and (min-width:992px) and (max-width:1199px){
              margin-bottom: 20px;
            }
          }
          .body{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width:100%;
            @media screen and (max-width:991px){
              padding: 0 30px;
            }
          }
          .trail-text{
            @media screen and (max-width:991px) {
                width: 100%;
              }
            .trail-content{
              @media screen and (max-width:991px) {
                background-color: rgba(0,0,0,0.5);
                height: 250px;
                width: 100%;
                padding-left: 28px;
                align-items: flex-start;
              }
            }
          }
        }
      }
    }
  }
  .custom-footer{
    background-color:#F4F4F4;
    @media screen and (max-width:991px) {
      background-color:#DFDFE0;
    }
    .footer-container{
      padding:30px 0px;
      @media screen and (max-width:991px) {
        flex-direction: column;
        align-items: flex-start;
      }
      @media screen and (min-width:992px) and (max-width:1199px){
        padding: 20px 0px;
      }
      .copyright-container{
        padding-right:20px;
        span{
          width: fit-content;
        }
        @media screen and (max-width:991px) {
          flex-direction: column;
          padding-right: 0px;
          margin-bottom: 42px;
        }
      }
      .terms-container{
        span{
          padding-right: 25px;
        }
      }
    }
  }

  .webkit-appearance-none{
    -webkit-appearance: none;
  }